.layout {
    color: var(--txt-color);
}

.layout__content {
    padding-left: var(--sidebar-width);
    background-color: var(--second-bg);
    min-height: 100vh;
}

.layout__content-main {
    padding: 30px;
}

.btn:focus {
    box-shadow: none !important;
}