.loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.5);
    display: flex;
    z-index: 10000;
    color: "white";
    justify-content: center;
    align-items: center;
    font-size: 4rem;
}

.loading-icon {
    font-size: 64px;
}